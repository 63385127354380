

#  optional params:
#  sarrow: { x: x, y: y, position:, side: , color: , length: , size:, instant: true }  
#    - add an arrow to the dialog pointing at this location
#    - arrow is at position: 'top', 'middle', 'bottom'
#    - arrow is at side: 'left', 'right', 'l', 'r'
#    - color is 'green', 'yellow', 'white', 'red', 'black' etc.
#    - length is 'long' or 'short', 'l', 's'
#    - size is 'xsmall' 'xs', 'small' 'sm' 's', 'medium' 'md', 'large' 'lg' 'l'
#    - x is additiional x offset
#    - y is additiional y offset
#    - instant: true  show instantly without transition
#    - instant_off: true  show instantly without transition
#

$ = jQuery

$document = $(document)
$window = $(window)


#$arrow_right = $('<svg version="1.1" width="32" height="32" viewBox="0 0 32 32" class="sarrow"><path  d="M31,16l-15,15v-9h-15v-12h15v-9z"></path></svg>')

$arrow_left = $('<svg version="1.1" width="32" height="32" viewBox="0 0 32 32" class="sarrow"><path f d="M1,16l15,15v-9h15v-12h-15v-9z"></path></svg>')

$arrow_left_long = $('<svg version="1.1" width="64" height="32" viewBox="0 0 64 32" class="sarrow"><path f d="M1,16l15,15v-9h47v-12h-47v-9z"></path></svg>')


window.jQuery.fn.sarrow = (params) ->
  $body = $('body')
  this.each ->
    $d = $(this)

    if params == 'off'
      ddom = $d[0]?._$sarrow
      do (ddom) ->
        ddom?.removeClass('showing')
        setTimeout ->
          ddom?.remove()
        , 1001
      return $d

    if params == 'instant_off'
      $d[0]?._$sarrow?.remove()
      return $d

    params = {}  unless params

    side = if params.side
      params.side
    else
      if $d.offset().left >  ($document.outerWidth() -  ($d.offset().left + $d.outerWidth() ))
        'left'
      else
        'right'

    position = if params.position
      params.position
    else
      'middle'


    switch params.length
      when 'short', 's'
        $arrow = $arrow_left.clone()
        switch params.size
          when 'xs', 'xsmall'
            my_width = 30
            my_height = 30
          when 's', 'sm', 'small'
            my_width = 50
            my_height = 50
          when 'l', 'lg', 'large'
            my_width = 200
            my_height = 200
          else # 'medium'
            my_width = 100
            my_height = 100
      else # 'long'
        $arrow = $arrow_left_long.clone()
        switch params.size
          when 'xs', 'xsmall'
            my_width = 60
            my_height = 30
          when 's', 'sm', 'small'
            my_width = 100
            my_height = 50
          when 'l', 'lg', 'large'
            my_width = 400
            my_height = 200
          else # 'medium'
            my_width = 200
            my_height = 100

    switch side
      when 'top', 'bottom', 't', 'b'
        [ my_wdith, my_height ] = [ my_height, my_width ]

    switch side
      when 'left', 'right', 'l', 'r'
        switch position
          when 'top', 't'
            top = $d.offset().top - ( 0.5 * my_height ) - (params.y || 0)
          when 'bottom', 'b'
            top = $d.offset().top + $d.outerHeight()  - ( 0.5 * my_height ) + (params.y || 0)
          else # 'middle'
            top = $d.offset().top + ( 0.5 * $d.outerHeight() ) -  ( 0.5 * my_height ) + (params.y || 0)
      when 'top', 't'
        top = $d.offset().top - ( my_height ) + (params.y || 0)
      when 'bottom', 'b'
        top = $d.offset().top + $d.outerHeight() + (params.y || 0)

    switch side
      when 'left', 'l'
        left = $d.offset().left - my_width - ( params.x || 0 )
        $arrow.css
          transform: 'rotate(180deg)'
          'transform-origin': 'center'
      when 'right', 'r'
        left = $d.offset().left + $d.outerWidth() + (params.x || 0)
      when 'top', 't'
        # todo position: left, right, middle.
        left = $d.offset().left + ( $d.outerWidth() / 2 ) -  ( my_width / 2 ) - ( params.x || 0 )
        $arrow.css
          transform: 'rotate(270deg)'
          'transform-origin': 'center'
      when 'bottom', 'b'
        # todo position: left, right, middle.
        left = $d.offset().left + ( $d.outerWidth() / 2 ) -  ( my_width / 2 ) - ( params.x || 0 )
        $arrow.css
          transform: 'rotate(90deg)'
          'transform-origin': 'center'
      else
        alert('sarrow side is not left,right,top, or bottom!')


    if params.color
      $arrow.addClass(params.color)

    $arrow.css
      top: top
      left: left
      width: my_width
      height: my_height

    if params.instant
      $arrow.addClass 'showing'

    $body.append($arrow)
    $d[0]._$sarrow?.remove()
    $d[0]._$sarrow = $arrow

    unless params.insant
      setTimeout =>
        $arrow.addClass 'showing'
      , 10

    return $d





