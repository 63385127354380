
$ = jQuery

window.tbanner_remove = ->
  $sb = $('div.tbanner')
  do ( $sb ) ->
    $sb.removeClass('showing')
    setTimeout ->
      $sb.remove()
    , 1001

# tbanner(text, { font-size: font_size, top: top, left: left, width: width: height: height, color: color, text-align: 'center' } )
#
# params can be used to over-ride default css

window.tbanner = (text = 'hello', params = {}) ->

  $sb = $("<div class='tbanner'><span>#{text}</span></div>")

  $sb.css params

  $('body').append $sb
  setTimeout ->
    $sb.addClass('showing')
  , 1001

  return $sb


