

#  optional params:
#  szoom: 
#

$ = jQuery

$document = $(document)
$window = $(window)



window.jQuery.fn.szoom = (params) ->
  $body = $('body')

  params = {} unless params
  params.duration ||= '1s' # default 1 second transition duration
  params.zoom_to ||= 0.8 # default 1 second transition duration

  this.each ->
    $d = $(this)

    if params == 'out'
      $body.css  transition: "transform #{params.duration} ease"
      $body.css('transform','')
      return $d

    if params == 'instant_out'
      $body.css  transition: ''
      $body.css('transform','')
      return $d



    { left, top }  = $d.offset()
    width = $d.outerWidth()
    height = $d.outerHeight()

    win_width = $window.outerWidth()
    win_height = $window.outerHeight()

    doc_width = $document.outerWidth()
    doc_height = $document.outerHeight()


    if width >= win_width
      console?.log "Elment too wide to zoom"
      return $d

    if height >= win_height
      console?.log "Elment too high to zoom"
      return $d

    width_cover = width / win_width
    height_cover = height / win_height

    if width_cover > height_cover
      largest_cover = width_cover
      target_width = params.zoom_to * win_width
      scale_factor = target_width / width
    else
      largest_cover = height_cover
      target_height = params.zoom_to * win_height
      scale_factor = target_height / height

    x_offset = Math.round(left + ( width / 2))

    # this y_offset assumes the element is already vertically centered
    y_offset = Math.round(top + ( height / 2 ))

    # this x_offset doesn't yet perfectly center the zoomed element
    x_offset = (left / (win_width - width)) *  win_width

    $body.css 'transform-origin': "#{x_offset}px #{y_offset}px"
    $body.css transition: "transform #{params.duration} ease"
    $body.css('transform', "scale(#{scale_factor})")


