
#

$ = jQuery


# stitle(title)
window.stitle = (title = 'hello') ->

  $st = $("<div class='stitle s_showing'><div class='s_mask'><div class='s_top'><img class='s_img' src='/logo/center/#{window.t('Rotaville')}.svg'></div></div><div class='s_mask'><div class='s_bottom'>#{title}</div></div></div>")
  $hc = $("<style id='hide_cursor' type='text/css'> * { cursor: none !important; }</style>").appendTo('head')


  $('body').append $st
  setTimeout ->
    $st.removeClass('s_showing')
    setTimeout ->
      $st.remove()
      $hc.remove()
    , 2100 # wait 2s for opacity to fade
  , 4001 # wait 3s  for text to start animating for 2s (text gone by 1s).


  return $st

# scredits(title)
window.scredits = (title) ->

  title ||= "#{t('Rota')} Software"

  $st = $("<div class='stitle s_slower'><div class='s_mask'><div class='s_top s_reverse'><img class='s_img' src='/logo/center/#{window.t('Rotaville')}.svg'></div></div><div class='s_mask'><div class='s_bottom s_reverse'>#{title}</div></div></div>")
  $hc = $("<style id='hide_cursor' type='text/css'> * { cursor: none !important; }</style>").appendTo('head')

  $('body').append $st
  setTimeout ->
    $st.addClass('s_showing')
  , 10

  setTimeout ->
    $st.remove()
    $hc.remove()
  , 8000

  return $st


window.sfadein = ->
  $hc = $("<style id='hide_cursor' type='text/css'> * { cursor: none !important; }</style>").appendTo('head')
  $('body').removeClass('faded')
  setTimeout ->
    $hc.remove()
  , 2000

window.sfadeout = ->
  $hc = $("<style id='hide_cursor' type='text/css'> * { cursor: none !important; }</style>").appendTo('head')
  $('body').addClass('faded')
  setTimeout ->
    $hc.remove()
  , 3000
  
