
#  optional params:
#  sbanner: { }
#
#

$ = jQuery


window.sbanner_remove = ->
  $sb = $('div.sbanner')
  do ( $sb ) ->
    $sb.removeClass('showing')
    setTimeout ->
      $sb.remove()
    , 1001

#
# sbanner({top: top, left: left, width: width: height: height}, text)
#  all mesuremens are percentages(0..100)%
#  top, left, right, bottom, height, width,  padding, background, color
#
#
window.sbanner = (params = {}, text = 'hello') ->

  $sb = $("<div class='sbanner'><div class='s_inner'>#{text}</div></div>")

  $s_inner = $sb.find('.s_inner')

  $sb.css params

  $('body').append $sb
  $s_inner.jl_maxtext()
  setTimeout ->
    $sb.addClass('showing')
  , 1001


  return $sb



$.fn.jl_maxtext = ->

  return this.each ->
    $section = $(this)

    unless $section.text()
      return

    max_height = $section.parent('div').height()
    max_width = $section.parent('div').width()

    $section.css({ display: 'table-cell', overflow: 'auto', opacity: '0'})
    $section.jl_maxtext_inc(max_height, max_width, 2)
    $section.css({ display: 'table-cell', overflow: 'hidden', opacity: '1.0'})



$.fn.jl_maxtext_inc = (max_height, max_width, current_size) ->

  return this.each ->
    $section = $(this)
    $section.css('font-size', current_size)
    s_height = $section.height()
    s_width = $section.width()

    while ((s_height <=  max_height) && (s_width <= max_width))
      # TODO use a while loop or binary search instead.
      current_size += 1
      $section.css('font-size', current_size)
      s_height = $section.height()
      s_width = $section.width()

    $section.css('font-size', current_size - 1 )


